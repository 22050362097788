import React from "react"
import { useStaticQuery, StaticQuery, graphql } from "gatsby"
import Img, { FluidObject } from "gatsby-image"

type ImageProps = {
  src: string
}

export const Image = (props: ImageProps) => {
  const data = useStaticQuery(graphql`
    query {
      images: allFile {
        edges {
          node {
            relativePath
            name
            childImageSharp {
              fluid(quality: 100, maxWidth: 1380) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const image = data.images.edges.find(n => {
    return n.node.relativePath.includes(props.src)
  })
  if (!image) {
    return null
  }
  return <Img fadeIn fluid={image.node.childImageSharp.fluid} {...props} />
}
